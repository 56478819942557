import { Cookies } from "react-cookie";
import { Auth } from "aws-amplify";
import jwtDecode from "jwt-decode";
import get from "lodash/get";
import gql from "graphql-tag";
import Api from "api/Api";
import { ApolloClient } from "@apollo/client";

const REGISTER_USER_SETFIELDS = gql`
  mutation SetUserFieldsMutation($id: String!, $fields: InputUserFields!) {
    setUserFields(fields: $fields, id: $id) {
      id
      firstName
      lastName
      avatarImageLink
      phone
      isActive
    }
  }
`;

const cookies = new Cookies();

async function register(
  credentials: {
    companyName: string;
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    Password: string;
    passwordVerification: string;
    userStatus: any;
  },
  client: any
) {
  const userState = await credentials.userStatus();
  await Auth.completeNewPassword(userState.cognitoStep, credentials.Password, {});
  const currentToken = await getUserToken();
  const token: any = jwtDecode(currentToken);
  await client.mutate({
    mutation: REGISTER_USER_SETFIELDS,
    variables: {
      id: token["custom:userId"],
      fields: {
        firstName: credentials.firstName,
        lastName: credentials.lastName,
        phone: credentials.phone,
        isActive: true,
      },
    },
  });
  return signIn({
    username: credentials.email,
    password: credentials.Password,
  });
}

async function verifyEmail(email: string) {
  await Api.post("/verify-email", email);
}

async function requestNewPassword(email: string) {
  return Auth.forgotPassword(email);
}

async function resetPassword(credentials: { email: string; password: string; token: string }) {
  try {
    await Auth.forgotPasswordSubmit(credentials.email, credentials.token, credentials.password);
    return await signIn({
      username: credentials.email,
      password: credentials.password,
    });
  } catch (error) {
    return error;
  }
}

const setDAuthCookie = (token: string) =>
  cookies.set("DAuth", token, {
    path: "/",
    domain: ".projectdado.com",
    sameSite: "lax",
    secure: true,
  });

async function signIn(credentials: { username: string; password: string }) {
  const response = await Auth.signIn(credentials.username.trim(), credentials.password);

  if (response.challengeName === "NEW_PASSWORD_REQUIRED") {
    return response;
  }
  const token = get(response, "signInUserSession.idToken.jwtToken");

  if (!token) throw new Error("User authenticated, could not retrieve token");
  setDAuthCookie(token);
  return response;
}

async function signOut(client: ApolloClient<unknown>, callback?: () => void) {
  cookies.remove("DAuth");
  client.clearStore().then(() => client.resetStore());
  await Auth.signOut();
  if (callback) {
    callback();
  }
}

async function getUserToken(): Promise<any> {
  // return false for SSR
  if (typeof window === "undefined") return false;
  try {
    const dataToken: any = await Auth.currentSession();
    if (dataToken) {
      const { idToken } = dataToken;
      const dAuth = cookies.get("DAuth");
      if (idToken.jwtToken !== dAuth) {
        setDAuthCookie(idToken.jwtToken);
      }
      return idToken.jwtToken;
    }
  } catch (error) {
    // console.log('getUserToken():', error);
  }
  return false;
}

function isLoggedIn(): any {
  return getUserToken();
}

export { signIn, register, signOut, getUserToken, isLoggedIn, verifyEmail, requestNewPassword, resetPassword };
