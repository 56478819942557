import gql from "graphql-tag";

export const COMPANY_LIST_FRAGMENT_GQL = gql`
  fragment CompanyListFragment on Company {
    id
    name
    address
    city
    state
    zipCode
    type
    projects {
      id
      name
      # size
    }
    projectsCount
    documentsCount
    staff {
      id
      userId
      companyId
      email
      roleAtCompany
      firstName
      lastName
    }
  }
`;

const COMPANY_LIST_QUERY_GQL = gql`
  query CompanyListQuery {
    companies {
      id
      ...CompanyListFragment
    }
  }
  ${COMPANY_LIST_FRAGMENT_GQL}
`;

export default COMPANY_LIST_QUERY_GQL;
