import useCompanyRoleRequired from "hooks/useCompanyRoleRequired";
import { UserRolesAtCompany } from "__generated__/globalTypes";
import { useCompanyIDAndProjectID } from "helpers/utils";
import { useIsSSR } from "hooks/useIsSSR";
import { useRouter } from "next/router";
import { ManualUploadDropzone } from "components/ManualUpload/ManualUploadDropzone";
import { ManualUploadManagerProvider } from "components/ManualUpload/ManualUploadManager";
import React from "react";

const DRAG_AND_DROP_ROUTES = new RegExp(/.*(admin|import-log|documents)$/i);

interface ManualUploadProps {
  children?: React.ReactNode;
}

/**
 *  Root component of the entire drag and drop functionality
 */
export const ManualUploadDropAndDrop = (props: ManualUploadProps) => {
  const { projectID } = useCompanyIDAndProjectID();
  const isAdmin = useCompanyRoleRequired(UserRolesAtCompany.Admin);
  const router = useRouter();
  const isSSR = useIsSSR();

  // Only activate drop zone on allowed pages
  // If (1) not on a project (2) not an admin (3) is SSR, then keep drop zone disabled but keep upload manager running
  const dropzoneEnabled = !projectID || !isAdmin || isSSR ? false : DRAG_AND_DROP_ROUTES.test(router.pathname);

  return (
    <ManualUploadManagerProvider>
      <ManualUploadDropzone enabled={dropzoneEnabled}>{props.children}</ManualUploadDropzone>
    </ManualUploadManagerProvider>
  );
};
