import { UserRolesAtCompany } from "__generated__/globalTypes";
import { useRouter } from "next/router";
import useQuery from "hooks/useQuery";
import { gql } from "@apollo/client";
import { usersRoleAtCompany, usersRoleAtCompanyVariables } from "__generated__/usersRoleAtCompany";

export const USERS_ROLE_AT_COMPANY_QUERY = gql`
  query usersRoleAtCompany($companyId: String!) {
    company(id: $companyId) {
      id
      role
    }
  }
`;

function checkForRole(userRole: UserRolesAtCompany, roleRequired: UserRolesAtCompany) {
  switch (roleRequired) {
    case UserRolesAtCompany.Owner:
      return userRole === UserRolesAtCompany.Owner;
    case UserRolesAtCompany.Admin:
      return userRole === UserRolesAtCompany.Admin || userRole === UserRolesAtCompany.Owner;
    case UserRolesAtCompany.Member:
      return (
        userRole === UserRolesAtCompany.Admin || userRole === UserRolesAtCompany.Owner || userRole === UserRolesAtCompany.Member
      );
  }
}

/**
 * Hook to check whether a user has the role required.
 * @param roleRequired Role required
 * @return true if the user has the role required, else false
 */
export default function useCompanyRoleRequired(roleRequired: UserRolesAtCompany) {
  const companyId = useRouter().query.companyID as Optional<string>;
  const { data, loading } = useQuery<usersRoleAtCompany, usersRoleAtCompanyVariables>(USERS_ROLE_AT_COMPANY_QUERY, {
    fetchPolicy: "cache-first",
    variables: { companyId: companyId! },
    skip: !companyId,
  });
  if (loading || !data?.company?.role) return false;
  return checkForRole(data?.company?.role, roleRequired);
}
