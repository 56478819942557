import styled, { keyframes } from "styled-components";
import CLOUD_ICON from "./cloud-upload-icon.svg";
import { Button } from "antd";

const StyledOuterDiv = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.8;
  z-index: 160;
  position: absolute;
  top: 0px;
  left: 0px;
  text-align: center;
  padding-top: 200px;
  content-visibility: auto;
`;

const StyledInnerDiv = styled.div`
  margin: auto;
  width: 60%;
  height: 60%;
  text-align: center;
  border: 5px #aaa dashed;
  background-color: #fff;
  padding-top: 75px;
`;

const bounceAnimation = keyframes`
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, .9) translateY(0);
  }
  30% {
    transform: scale(.9, 1.1) translateY(-25px);
  }
  50% {
    transform: scale(1.05, .95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-7px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
`;

const BouncingCloudIcon = styled.img`
  animation-name: ${bounceAnimation};
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  margin-top: 50px;
`;

const StyledH1 = styled.h1`
  font-size: 35px;
  color: #444;
`;

/**
 * The white-out overlay that appears when dragging/dropping
 */
export const DropzoneOverlay = ({ onClose }: { onClose?: VoidFunction }) => {
  return (
    <StyledOuterDiv>
      <StyledInnerDiv>
        <StyledH1>Drop files or folders here to import.</StyledH1>
        {onClose && (
          <div style={{ textAlign: "center" }}>
            <Button type="default" size="large" onClick={onClose}>
              Cancel
            </Button>
          </div>
        )}
        <BouncingCloudIcon src={CLOUD_ICON.src} width={100} />
      </StyledInnerDiv>
    </StyledOuterDiv>
  );
};
