import { ManualUploadJob, useManualUploadManager } from "components/ManualUpload/ManualUploadManager";
import { notification, Progress } from "antd";
import pluralize from "pluralize";
import CLOUD_ICON from "./cloud-upload-icon.svg";

/**
 * Component that renders the notifications for upload jobs
 */
export const useUploadNotifications = () => {
  const uploadManager = useManualUploadManager();

  Object.values(uploadManager.jobs).forEach((job) => {
    if (job.closed) return;
    let message: string;
    if (job.done) {
      message = pluralize(job.documentType.name, job.totalFiles) + " Uploaded";
    } else {
      message = "Uploading " + pluralize(job.documentType.name, job.totalFiles);
    }
    notification.open({
      icon: <img src={CLOUD_ICON.src} width={25} />,
      key: job.key,
      message: message,
      description: <UploadNotificationBody key={job.key} job={job} onCancel={() => uploadManager.cancelJob(job.key)} />,
      duration: 0,
      type: job.done ? "success" : "info",
      placement: "bottomRight",
      closeIcon: <span>Dismiss</span>,
      onClose: () => {
        uploadManager.hideJob(job.key);
      },
    });
  });

  return null;
};

const UploadNotificationBody = ({ job }: { job: ManualUploadJob; onCancel: (string) => void }) => {
  const percentComplete = Math.ceil((job.finishedFiles.length / job.totalFiles) * 100);
  return (
    <>
      {job.failed ? <div style={{ color: "red" }}>Job failed, please try again.</div> : null}
      {job.errors.length ? <div style={{ color: "red" }}>Some files may have failed to import, please try again.</div> : null}
      {job.canceled ? <div style={{ color: "red" }}>Import canceled.</div> : null}
      <Progress percent={percentComplete} />
      <br />
      Finished {job.finishedFiles.length}/{job.totalFiles} files.
      {percentComplete > 99 ? (
        <>
          <b>&nbsp;Done.</b>
          <br /> Uploaded documents will be available on DADO shortly.
        </>
      ) : (
        <h5 style={{ fontFamily: "monospace" }}>
          {job.finishedFiles.length ? job.finishedFiles[job.finishedFiles.length - 1].name : null}
        </h5>
      )}
      {job.errors.length
        ? job.errors.map((value) => (
            <div key={value} style={{ color: "red" }}>
              {value}
            </div>
          ))
        : null}
      {/*{!job.done && (*/}
      {/*  <div style={{ textAlign: "right" }}>*/}
      {/*    <a onClick={onCancel}>Cancel</a>*/}
      {/*  </div>*/}
      {/*)}*/}
    </>
  );
};
