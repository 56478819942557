import LoginLayout from "components/auth/LoginLayout";
import { Formik } from "formik";
import { Form, FormItem, Input, SubmitButton } from "formik-antd";
import React from "react";
import { CognitoUser } from "amazon-cognito-identity-js";
import * as Yup from "yup";
import { gql, useMutation } from "@apollo/client";
import { Auth } from "aws-amplify";
import { setUserFields, setUserFieldsVariables } from "__generated__/setUserFields";
import { signIn } from "helpers/authenticate";
import { useRouter } from "next/router";

const AFTER_LOGIN_REDIRECT = "/companies";

const SET_USER_FIELDS_GQL = gql`
  mutation setUserFields($id: String!, $fields: InputUserFields!) {
    setUserFields(fields: $fields, id: $id) {
      id
      firstName
      lastName
      phone
      isActive
    }
  }
`;

const LOWER_CASE_REGEX = /^(?=.*[a-z])/;
const UPPER_CASE_REGEX = /^(?=.*[A-Z])/;
const NUMERIC_REGEX = /^(?=.*[0-9])/;
export const PHONE_NUMBER_REGEX = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

const VALIDATION_SCHEMA = Yup.object({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  phone: Yup.string().optional().matches(PHONE_NUMBER_REGEX, "Must be a valid phone number"),
  password: Yup.string()
    .required("Required")
    .min(8, "Must be at least 8 characters")
    .matches(LOWER_CASE_REGEX, "Must contain at least one lowercase letter")
    .matches(UPPER_CASE_REGEX, "Must contain at least one uppercase letter")
    .matches(NUMERIC_REGEX, "Must contain at least one number"),
  passwordConfirm: Yup.string()
    .required("Required")
    .oneOf([Yup.ref("password")], "Passwords do not match"),
});

interface Props {
  user: CognitoUser;
}
export default function CreateProfile({ user }: Props) {
  const router = useRouter();
  const email = user.getUsername();
  const userID = (user as any).challengeParam.userAttributes["custom:userId"];
  const [setUserFields] = useMutation<setUserFields, setUserFieldsVariables>(SET_USER_FIELDS_GQL);

  return (
    <LoginLayout title="Complete your profile" titleStyle={{ marginTop: "20px" }}>
      <p>
        You’re almost there! Just a couple more details to finish creating your account.
        <br />
      </p>
      <Formik
        onSubmit={async (values) => {
          const { firstName, lastName, phone, password } = values;

          // set the user's new password on Cognito's end
          await Auth.completeNewPassword(user, password);

          // save the user's data on the backend with our gql mutation
          await setUserFields({
            variables: {
              id: userID,
              fields: { firstName, lastName, phone, isActive: true },
            },
          });

          await signIn({ username: email, password });
          router.push(AFTER_LOGIN_REDIRECT);
        }}
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          password: "",
          passwordConfirm: "",
        }}
        validationSchema={VALIDATION_SCHEMA}
      >
        {(formik) => {
          // https://developers.google.com/web/fundamentals/design-and-ux/input/forms#recommended_input_name_and_autocomplete_attribute_values
          return (
            <Form>
              <div style={{ display: "flex", gap: "10px" }}>
                <FormItem name="firstName">
                  <Input autoComplete="given-name" name="firstName" placeholder="First name" />
                </FormItem>
                <FormItem name="lastName">
                  <Input autoComplete="family-name" name="lastName" placeholder="Last name" />
                </FormItem>
              </div>
              <FormItem name="email">
                <Input disabled name="email" value={email} />
              </FormItem>
              <FormItem name="phone">
                <Input name="phone" placeholder="Phone (optional)" />
              </FormItem>
              <FormItem name="password" required>
                <Input.Password autoComplete="new-password" name="password" placeholder="Password" />
              </FormItem>
              <FormItem name="passwordConfirm" required>
                <Input.Password autoComplete="new-password" name="passwordConfirm" placeholder="Password (confirm)" />
              </FormItem>
              {/* keep the submit button disabled initially when the form loads and until all the field values are valid */}
              <p>
                By clicking “Sign Up,” you agree to our&nbsp;
                <a href="https://www.projectdado.com/privacy-policy/" target="blank">
                  Privacy Policy
                </a>
                &nbsp;and&nbsp;
                <a href="https://www.projectdado.com/terms-of-use/" target="blank">
                  Terms of Use.
                </a>
              </p>
              <SubmitButton
                // keep the submit button disabled initially when the form loads and until all the field values are valid
                disabled={!formik.dirty || !formik.isValid}
                style={{ width: "100%" }}
              >
                Sign Up
              </SubmitButton>
            </Form>
          );
        }}
      </Formik>
      <br />
    </LoginLayout>
  );
}
