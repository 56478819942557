import { DocumentType, FieldType } from "__generated__/globalTypes";
import { searchDocuments_customDocumentTypes, searchDocuments_customDocumentTypes_fields } from "__generated__/searchDocuments";
import { DocumentCoreFieldsFragment } from "__generated__/DocumentCoreFieldsFragment";
import { PublishedContractDrawingFragment } from "__generated__/PublishedContractDrawingFragment";
import { UnpublishedContractDrawingFragment } from "__generated__/UnpublishedContractDrawingFragment";
import { PublishedShopDrawingFragment } from "__generated__/PublishedShopDrawingFragment";
import { UnpublishedShopDrawingFragment } from "__generated__/UnpublishedShopDrawingFragment";
import { PublishedSubmittalFragment } from "__generated__/PublishedSubmittalFragment";
import { UnpublishedSubmittalFragment } from "__generated__/UnpublishedSubmittalFragment";
import { PublishedSpecificationFragment } from "__generated__/PublishedSpecificationFragment";
import { UnpublishedSpecificationFragment } from "__generated__/UnpublishedSpecificationFragment";
import { PublishedCustomDocumentFragment } from "__generated__/PublishedCustomDocumentFragment";
import { PublishedGenericFragment } from "__generated__/PublishedGenericFragment";
import { UnpublishedCustomDocumentFragment } from "__generated__/UnpublishedCustomDocumentFragment";
import { UnpublishedGenericFragment } from "__generated__/UnpublishedGenericFragment";
import {
  DocumentTypesFragment_documentTypes_types,
  DocumentTypesFragment_documentTypes_types_fields,
  DocumentTypesFragment_documentTypes_types_fields_valueMapping,
} from "__generated__/DocumentTypesFragment";
import { FieldsFragment } from "__generated__/FieldsFragment";

export interface DocumentTypeMeta {
  abbreviation: string;
  customTypeId: string | null;
  documentType: DocumentType;
  name: string;
  singularName: string;
}

// From Legacy - src/project/documents/_components/card/_helpers/documentsCardHelper.ts
export const pairWithFallback = (value1: string | null | undefined, value2: string | null) => {
  if (value1 && value2) {
    return `${value1} – ${value2}`;
  } else if (value2) {
    return value2;
  }
  return "-";
};

// Derive common types from GQL Fragments
export type ContractDrawing = DocumentCoreFieldsFragment &
  (PublishedContractDrawingFragment | UnpublishedContractDrawingFragment);
export type ShopDrawing = DocumentCoreFieldsFragment & (PublishedShopDrawingFragment | UnpublishedShopDrawingFragment);
export type Submittal = DocumentCoreFieldsFragment & (PublishedSubmittalFragment | UnpublishedSubmittalFragment);
export type Specification = DocumentCoreFieldsFragment & (PublishedSpecificationFragment | UnpublishedSpecificationFragment);
export type CustomDocument = DocumentCoreFieldsFragment & (PublishedCustomDocumentFragment | UnpublishedCustomDocumentFragment);
export type GenericDocument = DocumentCoreFieldsFragment & (PublishedGenericFragment | UnpublishedGenericFragment);
export type Document = ContractDrawing | ShopDrawing | Submittal | Specification | CustomDocument | GenericDocument;

// Type guards to allow us to specify fields in conditionals - https://www.typescriptlang.org/docs/handbook/advanced-types.html#user-defined-type-guards
export const isContractDrawing = (doc: Pick<Document, "documentType">): doc is ContractDrawing => {
  return doc.documentType === DocumentType.ContractDrawing;
};
export const isShopDrawing = (doc: Pick<Document, "documentType">): doc is ShopDrawing => {
  return doc.documentType === DocumentType.ShopDrawing;
};
export const isSubmittal = (doc: Pick<Document, "documentType">): doc is Submittal => {
  return doc.documentType === DocumentType.Submittal;
};
export const isSpecification = (doc: Pick<Document, "documentType">): doc is Specification => {
  return doc.documentType === DocumentType.Specification;
};
export const isCustomDocument = (doc: Pick<Document, "documentType">): doc is CustomDocument => {
  return doc.documentType === DocumentType.CustomDocument;
};
export const isGenericDocument = (doc: Pick<Document, "documentType">): doc is GenericDocument => {
  return doc.documentType === DocumentType.GenericDocument;
};
export const supportsVersioning = (doc: Document): boolean => {
  // Does the document type support versioning? Used to control the UI on whether the show version related UI components.
  // See https://app.zeplin.io/project/5fa03d9b4f5bd181a47410da/screen/5fa04ac2bf02e73198d23aaf
  return !!(isShopDrawing(doc) || isContractDrawing(doc) || isSpecification(doc) || isCustomDocument(doc));
};
export const isDrawing = (doc: Pick<Document, "documentType">): boolean => {
  return !!(isShopDrawing(doc) || isContractDrawing(doc));
};

export const handleFormatDocumentTitle = (document: Pick<Document, "documentType" | "title">) => {
  if (document.documentType === DocumentType.ContractDrawing && isContractDrawing(document)) {
    return document.sheetNumber?.text || "";
  } else if (document.documentType === DocumentType.ShopDrawing && isShopDrawing(document)) {
    return document.sheetNumber?.text || "";
  } else if (document.documentType === DocumentType.Submittal && isSubmittal(document)) {
    return pairWithFallback(document.submittalNumber, document.title);
  } else if (document.documentType === DocumentType.Specification && isSpecification(document)) {
    return document.specificationSection?.sectionNumberWithName || "-";
  } else {
    return document.title || "";
  }
};
export const handleFormatDocumentInfo = (document: Document) => {
  if (document.documentType === DocumentType.ContractDrawing && isContractDrawing(document)) {
    return document.sheetTitle?.text || "";
  } else if (document.documentType === DocumentType.ShopDrawing && isShopDrawing(document)) {
    return document.sheetTitle?.text || "";
  } else if (document.documentType === DocumentType.Submittal && isSubmittal(document)) {
    return document.status || "";
  } else {
    return "";
  }
};

/**
 *  In cases when we only have the document ID and no other day, we can extract the document type information directly from the ID
 */
export const getDocumentTypeFromDocumentId = (documentId: string): HasDocTypeFields => {
  const parts = documentId.split("|");
  let docType: DocumentType;
  let customTypeId: Nullable<string> = null;
  switch (parts[0].substring(0, 3)) {
    case "cde":
      docType = DocumentType.ContractDrawing;
      break;
    case "sbe":
      docType = DocumentType.Submittal;
      break;
    case "spe":
      docType = DocumentType.Specification;
      break;
    case "sde":
      docType = DocumentType.ShopDrawing;
      break;
    case "gne":
      docType = DocumentType.GenericDocument;
      break;
    case "cue":
      docType = DocumentType.CustomDocument;
      customTypeId = parts[0].split(":")[1];
      break;
    default:
      throw new Error(`Unknown document type abbreviation ${parts[0]} in document id`);
      break;
  }

  const documentType: HasDocTypeFields = {
    documentType: docType,
    customTypeId: customTypeId,
  };

  return documentType;
};

export const BUILT_IN_DOCUMENT_TYPES: Record<string, DocumentTypeMeta> = {
  ContractDrawing: {
    documentType: DocumentType.ContractDrawing,
    customTypeId: "ContractDrawing",
    name: "Project Drawings",
    singularName: "Project Drawing",
    abbreviation: "PD",
  },
  Submittal: {
    documentType: DocumentType.Submittal,
    customTypeId: "Submittal",
    name: "Submittals",
    singularName: "Submittal",
    abbreviation: "SB",
  },
  Specification: {
    documentType: DocumentType.Specification,
    customTypeId: "Specification",
    name: "Specifications",
    singularName: "Specification",
    abbreviation: "SP",
  },
  ShopDrawing: {
    documentType: DocumentType.ShopDrawing,
    customTypeId: "ShopDrawing",
    name: "Shop Drawings",
    singularName: "Shop Drawing",
    abbreviation: "SD",
  },
  GenericDocument: {
    documentType: DocumentType.GenericDocument,
    customTypeId: "GenericDocument",
    name: "Other",
    singularName: "Other",
    abbreviation: "OD",
  },
};

export interface DocumentTypeMetaMap {
  [key: string]: DocumentTypeMeta;
}

export interface HasDocTypeFields {
  customTypeId: string | null;
  documentType: DocumentType;
}

export const toDocumentTypeKey = (documentType: string, customTypeId?: NullableAndOptional<string>): string => {
  if (customTypeId) {
    return `${documentType}:${customTypeId}`;
  } else {
    return documentType;
  }
};

export const createDocumentTypeKey = (d?: HasDocTypeFields) =>
  d ? toDocumentTypeKey(d.documentType, d.customTypeId) : undefined;

export interface HasDocTypeFieldsWithName extends HasDocTypeFields {
  name: string;
}

export const toDocumentTypesMap = (documentTypes: HasDocTypeFields[] | undefined): Record<string, DocumentTypeMeta> => {
  if (documentTypes) {
    return documentTypes?.reduce((acc: AnyObject, dt: DocumentTypesFragment_documentTypes_types) => {
      acc[toDocumentTypeKey(dt.documentType, dt.customTypeId)] = dt;
      return acc;
    }, {});
  } else {
    return {};
  }
};

interface HasId {
  id: string;
}

export const toMapById = <TData extends HasId>(items: TData[] | undefined): Record<string, TData> => {
  if (items) {
    return items.reduce((acc: AnyObject, dt: TData) => {
      acc[dt.id] = dt;
      return acc;
    }, {});
  } else {
    return {};
  }
};

export const toCustomIssueDateName = (customDocMeta: Nullable<searchDocuments_customDocumentTypes>) => {
  if (customDocMeta) {
    const issueDateFields = customDocMeta.fields.filter((i: searchDocuments_customDocumentTypes_fields) => {
      return i.type === FieldType.IssueDate;
    });
    if (issueDateFields.length === 1) {
      return issueDateFields[0].name;
    }
  }
  return "Issue Date";
};

/**
 *  Download code inspired by old web app -> src/project/documents/routes/Documents.tsx
 */
const downloadURL = (data: string, filename: string) => {
  const a: HTMLAnchorElement = document.createElement("a");
  a.href = data;
  a.download = filename;
  document.body.appendChild(a);
  a.style.display = "none";
  a.click();
  a.remove();
};

const downloadFromUrl = (url: string, filename: string) => {
  return fetch(url, {
    mode: "cors",
  })
    .then((response) => response.blob())
    .then((blob) => {
      const urlFromBlob = URL.createObjectURL(blob);
      downloadURL(urlFromBlob, filename);
      return urlFromBlob;
    })
    .then((blobUrl) => {
      return setTimeout(() => {
        return window.URL.revokeObjectURL(blobUrl);
      }, 1000);
    })
    .catch((e) => console.log(e));
};

export const toDownloadFileName = (document: Pick<Document, "documentType" | "title" | "originalFile">) => {
  const title = handleFormatDocumentTitle(document);
  return title === "" ? document.originalFile.name : `${title}.pdf`;
};

export const downloadSinglePdf = (document: Document) => {
  return downloadFromUrl(document.sectionPDFURL, toDownloadFileName(document));
};

export const isFromABuiltinType = (fieldId: string): boolean => {
  return fieldId.startsWith("00000000-0000-0000");
};

export const toMapByInput = (
  items: DocumentTypesFragment_documentTypes_types_fields_valueMapping[] | undefined | null
): Record<string, string> | undefined => {
  if (items) {
    return items.reduce((acc: AnyObject, dt: DocumentTypesFragment_documentTypes_types_fields_valueMapping) => {
      // Some fields (Import Source) have a null as their value but we can't use null as a key on a map so
      // toStringNull changes null to 'null'
      acc[toStringNull(dt.input)] = dt.output;
      return acc;
    }, {});
  }
};

export const toStringNull = (input: any | null | undefined) => {
  // return 'null' as string if input is undefined or null only.
  return input === undefined || input === null ? "null" : input;
};

export interface DocumentTypeFieldEnhanced extends FieldsFragment {
  valueMap: Record<string, string>;
}

export const addValueMappingMap = (fields: FieldsFragment[]): DocumentTypeFieldEnhanced[] => {
  if (!fields) return [];
  return fields.map<DocumentTypeFieldEnhanced>((f: DocumentTypesFragment_documentTypes_types_fields) => {
    let valueMap;
    if (f.valueMapping) {
      valueMap = toMapByInput(f.valueMapping);
    }
    return { ...f, valueMap };
  });
};

export const normaliseDate = (value: string): string => {
  // Custom Date fields are returning Z in timezone indicating UTC which then causes browser to convert to local
  // timezone -> https://linqsoftware.atlassian.net/browse/PD-1586
  // Converts 2020-05-28T00:00:00.000Z to 2020-05-28T00:00:00.000
  if (value === undefined || value === null) {
    return value;
  }
  value = value.trim();
  if (value.length > 0 && value.toUpperCase().endsWith("Z")) {
    return value.substring(0, value.length - 1);
  }
  return value;
};

interface HasFieldId {
  fieldId: string;
}

export const toMapByFieldId = <TData extends HasFieldId>(items: TData[] | undefined): Record<string, TData> => {
  if (items) {
    return items.reduce((acc: AnyObject, dt: TData) => {
      acc[dt.fieldId] = dt;
      return acc;
    }, {});
  } else {
    return {};
  }
};

/**
 * Util function, takes any string and pulls out the spec section
 * Input:  "3451 24.3 - Plumbing"
 * Output: "34 51 24.3"
 */
export function formatSpecNumber(value: Nullable<string>): Nullable<string> {
  if (!value) return null;
  const result = value.match(/(\d\d)\s*(\d\d)?\s*(\d\d[.]?[\d]*)?/);
  if (!result) return null;
  result.shift();
  return result.filter((value) => value !== undefined).join(" ");
}

/**
 * Util function, pulls the name out of the spec section
 */
export function formatSpecName(value: Nullable<string>): Nullable<string> {
  if (!value) return null;
  const result = value.match(/[\d\s.\\–\\-]*([\D\s]*)$/);
  if (!result) return null;
  const name = result.pop();
  if (!name) return null;
  return name;
}
