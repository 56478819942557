import { useMutation } from "@apollo/client";
import { IngestUploadedDocuments, IngestUploadedDocumentsVariables } from "__generated__/IngestUploadedDocuments";
import { INGEST_UPLOAD_DOCUMENTS } from "gql/ingestion";
import { InputUploadedDocument } from "__generated__/globalTypes";
import { useCompanyIDAndProjectID } from "helpers/utils";
import { growlWarning } from "components/common/Growl";

/**
 * Triggers ingestion
 */
export const useIngestUploadedDocuments = () => {
  const { projectID } = useCompanyIDAndProjectID();

  const [ingestDocumentMutation] = useMutation<IngestUploadedDocuments, IngestUploadedDocumentsVariables>(
    INGEST_UPLOAD_DOCUMENTS
  );

  // Added a second optional parameter for the projectId, because people can change projects mid-import
  const startIngestion = (documents: InputUploadedDocument[], projectIdForImportJob?: string) => {
    return ingestDocumentMutation({
      variables: {
        documents: documents,
        projectId: projectIdForImportJob || projectID!,
      },
    }).catch((error) => {
      growlWarning(error);
      console.error(error);
      return Promise.reject(error);
    });
  };

  return {
    startIngestion,
  };
};
