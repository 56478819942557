import gql from "graphql-tag";
import { COMPANY_LIST_FRAGMENT_GQL } from "./companyListQuery.gql";

export const USER_CURRENT_QUERY_GQL = gql`
  query UserCurrentQuery {
    currentUser {
      id
      email
      firstName
      lastName
      avatarImageLink
      phone
      companies {
        id
        ...CompanyListFragment
      }
    }
  }
  ${COMPANY_LIST_FRAGMENT_GQL}
`;
