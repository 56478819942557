import React from "react";

/**
 * Warns users who open their browser console on our production application about Self-XSS.
 * https://en.wikipedia.org/wiki/Self-XSS
 */
export class SelfXSSWarning extends React.Component<EmptyObject, EmptyObject> {
  componentDidMount() {
    if (process.browser && process.env.NODE_ENV !== "development") {
      setTimeout(
        console.log.bind(
          console,
          "%c%s",
          "font-size:40px; font-weight:bold; background: yellow; color:red; -webkit-text-stroke:1px black;",
          " WARNING! "
        )
      );
      setTimeout(
        console.log.bind(
          console,
          "%cUsing this console may allow attackers to impersonate you and steal your information using an attack called Self-XSS.\n%cDo not enter or paste code that you do not understand.",
          "font-family:helvetica; font-size:16px;",
          "font-family:helvetica; font-size:16px; font-weight:bold"
        )
      );
    }
  }
  render() {
    return null;
  }
}
