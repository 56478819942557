import axios from "axios";

const config = {
  baseURL: `${process.env.NEXT_PUBLIC_API_REST_URL}:${process.env.NEXT_PUBLIC_API_REST_PORT}`,
  timeout: 60000,
  headers: {
    "Cache-Control": "no-store, no-cache, must-revalidate",
    Expires: "0",
  },
  withCrendentials: true,
};

// create Axios Instance as Api
const Api = axios.create(config);

Api.interceptors.request.use((reqConfig) => {
  // Placeholder here to do something here before sending out the request
  console.trace("HTTP Request: ", reqConfig);
  return reqConfig;
});

// whenever a response is received from the node layer
Api.interceptors.response.use(
  (response) => {
    const status = response.status;
    console.trace("HTTP Response: ", response);
    if (status < 200 || status > 401) {
      console.error(response);
      return Promise.reject(status);
    }
    return response;
  },
  // eslint-disable-next-line
  (error) => {
    if (error.response?.status !== 401) {
      console.error("API Error: ", error);
    }
    // throw new Error(JSON.stringify(error))
    return Promise.reject(error);
  }
);

export default Api;
