import { notification } from "antd";
import { ArgsProps as AntdNotificationArgsProps } from "antd/lib/notification";
import React from "react";

/**
 * Antd's notification.success() with some defaults.
 * Default duration: 5s (unless otherwise specified in `rawArgs`)
 */
export const growlSuccess = (message: React.ReactNode, rawArgs: Omit<AntdNotificationArgsProps, "message"> = {}): void => {
  const args: AntdNotificationArgsProps = { duration: 2, placement: "bottomLeft", ...rawArgs, message };
  notification.success(args);
};

// Accepts react components, a string, or an object such as a javascript exception that has toString
// 2nd parameter is optional antd notification args
export const growlWarning = (
  message: React.ReactNode | React.ReactNode[] | { toString: () => string },
  args?: Partial<AntdNotificationArgsProps>
): void => {
  // If not a react-like object
  if (!(message instanceof Array) && !React.isValidElement(message)) {
    message = message + ""; // trigger toString()
  }
  const theArgs = Object.assign(
    {
      message: <>{message}</>,
      duration: 4.5,
      placement: "bottomLeft",
    },
    args || {}
  );
  notification.warning(theArgs);
};
