import { Col, Row } from "antd";
import { toDefaultDate } from "helpers/utils";
import styled from "styled-components";

const StyledH4 = styled.h4`
  margintop: 25px;
`;

const StyledH3 = styled.h3`
  margintop: 10px;
`;

const StyledDiv = styled.div`
  height: 300px;
  overflow: auto;
  padding: 5px;
  border: 1px solid #ccc;
`;

const StyledHeaderCol = styled(Col)`
  font-weight: bold;
`;

interface FilelistProps {
  files: Nullable<File[]>;
}

/**
 *  Displays list of files to upload
 */
export const Filelist = ({ files }: FilelistProps) => {
  if (!files) {
    return <StyledH4>Gathering files...</StyledH4>;
  }
  return (
    <>
      <StyledH3>Files</StyledH3>
      <StyledDiv>
        <Row>
          <StyledHeaderCol span={14}>File name</StyledHeaderCol>
          <StyledHeaderCol span={6}>Last modified</StyledHeaderCol>
          <StyledHeaderCol span={4}>Size</StyledHeaderCol>
        </Row>
        {files.map((file) => {
          const fileSize = (+file.size / 1000).toLocaleString(undefined, { maximumFractionDigits: 0 });
          return (
            <Row key={file.name + file.size}>
              <Col span={14}>{file.name}</Col>
              <Col span={6}>{toDefaultDate(new Date(file.lastModified) + "")}</Col>
              <Col span={4}>
                {fileSize}
                <small> KB</small>
              </Col>
            </Row>
          );
        })}
      </StyledDiv>
    </>
  );
};
