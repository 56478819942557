import { gql } from "@apollo/client";

const GET_DOCUMENT_TYPES = gql`
  query documentTypes($projectId: String!) {
    project(id: $projectId) {
      id
      documentTypes {
        name
        documentType
        customTypeId
        type
      }
    }
  }
`;

export default GET_DOCUMENT_TYPES;
